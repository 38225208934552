import React from "react";

import SearchView from "../../../components/SearchView/SearchView";
import { loader } from "graphql.macro";

const AuditQuery = loader("./Audit.graphql");

import AuditCard from "./AuditCard";
import AuditCols from "./AuditCols.json";

const AuditsPage = () => {
  return (
    <SearchView
      Item={AuditCard}
      searchQuery={AuditQuery}
      columns={AuditCols}
      filters={[]}
      title="Audits"
    />
  );
};

export default AuditsPage;
