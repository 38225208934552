
const dot = require('dot-object');
import React, { useState, useEffect } from 'react';
import { TextField, FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { namedArray } from './util';

function View({ question, context }) {

  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    output: context.pick('value') || '',
    search: ''
  });
  const [groups, setGroups] = useState([]);

  const validate = (formValues) => {
    let tmp = { ...formErrors };

    // browser validation... // TODO: FormHelperText
    // Object.keys(formValues).map(key=>tmp[key]=document.getElementById(key).validationMessage);
    console.warn(formValues);

    // any additional validation...
    // nada

    setFormErrors(tmp);
  };

  useEffect(() => {
    filter(''); // start with an empty filter
  }, [question])

  const filter = (query) => {
    const g = question.input.groups.map(g=>{
      g.items = namedArray(g).filter(item=>{
        item.locale = question.properties.locale[item.locale_key];
        item.locale_more = question.properties.locale[item.locale_key_more];
        return JSON.stringify(item).indexOf(query) > -1;
      });
      return g;
    });
    setGroups(g);
  };

  const handleInputChange = (e) => {
    const { name } = e.target;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (name === 'search') filter(value);

    let values = {...formValues};
    dot.str(name, value, values);

    if (question.input.filtered === true) {
      Object.keys(values.output).forEach(k=>{
        if (values.output[k] === false) { delete values.output[k]; }
      })
    }

    setFormValues(values);
    validate({ [name]: value });
    context.assign({
      value: values.output
    });
  };

  return (
    <div dir={question.properties.locale.dir} className="inputs inputs-check-list">
      {
        question.input.searchable && (
          <FormControl error fullWidth>
            <TextField
              id="searchable"
              name="search"
              label={question.properties.locale.searchable}
              variant="filled"
              value={formValues.search}
              onChange={handleInputChange}
              autoFocus
              inputProps={{
                inputref: 'ref-searchable'
              }}
              autoComplete="off"
              aria-describedby="searchable-helper-text"
            />
          </FormControl>
        )
      }

      {
        groups.map((g, i)=>{
          return (
            <FormGroup key={i}>
              <h2>{question.properties.locale[g.locale_key]}</h2>
              {
                g.items.map(item=>{
                  return(
                    <FormControlLabel
                      key={item.id}
                      label={
                        <React.Fragment>
                          <h3>{question.properties.locale[item.locale_key]}</h3>
                          {item.locale_key_more && (
                            <p>{question.properties.locale[item.locale_key_more]}</p>
                          )}
                        </React.Fragment>
                      }
                      control={
                        <Checkbox
                          id={`ref-${item.id}`}
                          name={`output.${item.id}`}
                          required={question.input.required}
                          readOnly={question.input.readonly}
                          value={true}
                          inputProps={{
                            maxLength: question.input.maxlength ? question.input.maxlength : 80,
                            inputref: `ref-${item.id}`
                          }}
                          onChange={handleInputChange}
                        />
                      }
                    />
                  )
                })
              }
            </FormGroup>
          )
        })
      }
    </div>
  );
}

export default View;
