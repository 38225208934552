
import React from 'react';
import { Typography } from '@mui/material';

//
function notNull(msg, variant) {
  return (
    <Typography
      variant={variant || 'subtitle2'}
      style={{
        backgroundColor: 'yellow',
        display: 'inline'
      }}
    >
      {msg || 'Not provided'}
    </Typography>
  )
}

export { notNull };
