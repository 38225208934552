import React from "react";

import SearchView from "../../../components/SearchView/SearchView";
import { loader } from "graphql.macro";

const FilingQuery = loader("./Filing.graphql");

import FilingCard from "./FilingCard";
import FilingCols from "./FilingCols.json";

const FilingsPage = () => {
  return (
    <SearchView
      Item={FilingCard}
      searchQuery={FilingQuery}
      columns={FilingCols}
      filters={[]}
      title="Filings"
    />
  );
};

export default FilingsPage;
