
const dot = require('dot-object');
import React, { useState } from 'react';
import { TextField, FormControl, FormHelperText } from '@mui/material';

function View({ question, context }) {

  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    output: context.pick('value') || ''
  });

  const validate = (formValues) => {
    let tmp = { ...formErrors };

    // browser validation... // TODO
    console.warn(formValues);

    // any additional validation...
    // nada

    setFormErrors(tmp);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let values = {...formValues};
    dot.str(name, value, values);
    setFormValues(values);
    validate({ [name]: value });
    context.assign({
      value: value
    });
  };

  return (
    <FormControl error fullWidth>
      <TextField
        id="email"
        name="output"
        type="email"
        label={question.properties.locale.email}
        variant="filled"
        value={formValues.output}
        onChange={handleInputChange}
        required={question.input.required}
        readOnly={question.input.readonly}
        autoFocus
        inputProps={{
          inputref: 'ref-email',
          pattern: '[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$'
        }}
        autoComplete="off"
        aria-describedby="email-helper-text"
      />
      <FormHelperText id="email-helper-text" style={{fontSize:'0.9rem'}}>{formErrors.email}</FormHelperText>
    </FormControl>
  );
}

export default View;
