import React, {} from 'react';
import { Card, CardContent } from '@mui/material';

const RevenueEstimateCard = ({ item }) => {
  return (
    <Card className="dashboardCard dashboardCardRevenueEstimates">
      <CardContent>
        <div>{JSON.stringify(item)}</div>
      </CardContent>
    </Card>
  );
}

export default RevenueEstimateCard;
