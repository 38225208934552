//
import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, List, ListItem, ListItemText, ListItemIcon, ListSubheader, Chip, Alert } from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { faCalendar, faUser, faEnvelope, faHome, faHourglassStart, faHourglassHalf, faHourglassEnd, faFileUpload, faEnvelopeOpenText, faUpload, faShareSquare } from "@fortawesome/free-solid-svg-icons";

import { LoadingList } from '../../../common/loading';
import { notNull } from '../../common/not-null';
import { statusColorMap } from '../../common/color-map';

//
const GET_FIRST = gql`
  query($queryApprovals:KibanaQL=\"\" $queryUploads:KibanaQL=\"\" $id:ID!) {
    approvals(search: { query:$queryApprovals }) { hits { id explanation key status label foreign_id created_at updated_at extra foreign_type approver { name email } } }

    audit(id:$id) { id created_at type foreign_id auditee {
name email telephone} listings { id type value uploads} status extra invoice_id jurisdiction_id}

    auditUploads: uploads(search: { query:$queryUploads }) { hits { id extra foreign_id files { id original_filename url uploaded_at previews { url mimetype } } }
    }
  }
`;

const GET_SECOND = gql`
  query($jurisdictionId:ID! $letterId:ID! $auditId:ID!) {
    jurisdiction(id:$jurisdictionId) { id slug name assessor_url stripe_key }
    letter(id:$letterId) { pdf_url created_at }
    filingPeriods(jurisdiction_id:$jurisdictionId foreign_type:AUDIT foreign_id:$auditId) { period { start_at end_at } }
  }
`;

const GET_THIRD = gql`
  query($queryUploads:KibanaQL=\"\") {
    auditListingUploads: uploads(search: { query:$queryUploads }) { hits { id extra foreign_id files { id original_filename url uploaded_at previews { url mimetype } } } }
  }
`;

const GET_PERMIT = gql`
  query($jurisdictionId:ID! $id:ID!) {
    subject:permit(jurisdiction_id:$jurisdictionId number:$id) { id jurisdiction_id number parcel_number status flagged_issues registrant_relation rental_unit_details { bedrooms bathrooms parking_spaces maximum_occupancy } situs_address { concatenated formatted unit_number } emergency { name telephone email } owner { name business_name telephone email mailing_address { concatenated formatted } } registrant { name email telephone mailing_address { concatenated formatted line_1 line_2 city state postal_code country_code } } manager { name telephone email mailing_address { concatenated formatted } } extra }
  }
`;

const GET_RENTAL_UNIT = gql`
  query($id: ID!) {
    subject:rentalUnit(id:$id) { id situs_address { concatenated formatted unit_number } }
  }
`;

//
function View({ match }) {

  useEffect(() => {
    document.title = 'Audit | Host Compliance';
  });

  //
  const [data, setData] = useState({ loaded: false });
  const [errors, setErrors] = useState([]);

  const { data:firstData, loading:firstLoading, error:firstError } = useQuery(GET_FIRST, {
    variables: {
      queryApprovals: `foreign_type:AUDIT foreign_id:${match.params.auditId}`,
      queryUploads: `foreign_type:AUDIT foreign_id:${match.params.auditId}`,
      id: match.params.auditId
    }
  });

  const { data:secondData, loading:secondLoading, error:secondError } = useQuery(GET_SECOND, {
    skip: firstLoading,
    variables: {
      jurisdictionId: firstData && firstData.audit.jurisdiction_id,
      letterId: firstData && firstData.audit.letter_id || 148174, // TODO: not hard coded
      auditId: firstData && firstData.audit.id
    }
  });

  const { data:thirdData, loading:thirdLoading, error:thirdError } = useQuery(GET_THIRD, {
    skip: firstLoading,
    variables: {
      queryUploads: firstData && `foreign_type:AUDIT_LISTING foreign_id:(${firstData.audit.listings.map(o=>o.id).join(' OR ')})`
    }
  });

  const { data:permitData, loading:permitLoading, error:permitError } = useQuery(GET_PERMIT, {
    skip: !firstLoading ? firstData.audit.type !== 'PERMIT' : true,
    variables: {
      id: firstData && firstData.audit.foreign_id,
      jurisdictionId: firstData && firstData.audit.jurisdiction_id
    }
  });

  const { data:rentalUnitData, loading:rentalUnitLoading, error:rentalUnitError } = useQuery(GET_RENTAL_UNIT, {
    skip: !firstLoading ? firstData.audit.type !== 'RENTAL_UNIT' : true,
    variables: {
      id: firstData && firstData.audit.foreign_id
    }
  });

  useEffect(() => {
    setData(Object.assign({ loaded: true }, firstData, secondData, thirdData, permitData, rentalUnitData));

    if (firstError) setErrors(errors.concat(firstError));
    if (secondError) setErrors(errors.concat(secondError));
    if (thirdError) setErrors(errors.concat(thirdError));
    if (permitError) setErrors(errors.concat(permitError));
    if (rentalUnitError) setErrors(errors.concat(rentalUnitError));
  }, [firstLoading, secondLoading, thirdLoading, permitLoading, rentalUnitLoading]);

  //
  const statusMap = {
    REQUESTED: faHourglassStart,
    NOTICE_SENT: faHourglassStart,
    OVERDUE: faHourglassHalf,
    CANCELLED:  faHourglassEnd,
    SUBMITTED: faHourglassHalf,
    ACCEPTED:  faHourglassEnd,
    INCOMPLETE: faHourglassHalf,
    ESCALATED: faHourglassEnd
  };

  //
  return (
    <Grid container spacing={1}>
      {errors.length > 0 && (
        <Grid item xs={12}>
          <Alert severity="error">Error! {errors.length}</Alert>
        </Grid>
      )}

      <Grid item xs={12} md={9}>
        <Card>
          <CardContent>
            {!data && LoadingList()}

            {data.loaded && data.audit && data.auditListingUploads && data.audit.listings.map((listing, index)=>{
              return(
                <div key={listing.id}>
                  <h1>{index+1}/{data.audit.listings.length} Listing ID: {listing.id}</h1>
                  <h3>{listing.value} • {listing.uploads.length} uploads</h3>

                  {listing.uploads && listing.uploads.map((id)=>{
                    const upload = data.auditListingUploads.hits.filter(o=>o.id===id)[0];
                    return (
                      <p key={upload.id}>{upload.extra.label} | {upload.files.length} file(s) | ID: {upload.id} | FID: {upload.foreign_id}</p>
                    )
                  })}
                </div>
              )
            })}
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            {!data && LoadingList()}
            <h1>Filing</h1>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={3}>
        <Card>
          <CardContent>
            {!data && LoadingList()}

            {data.loaded && (
              <List dense={true}>
                <ListSubheader>Audit Overview</ListSubheader>

                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faCalendar} />
                  </ListItemIcon>
                  <ListItemText
              primary={data.filingPeriods && `${dayjs(data.filingPeriods.period.start_at).format('MMMM D, YYYY')} to ${dayjs(data.filingPeriods.period.end_at).format('MMMM D, YYYY')}`}
                    secondary="Audit Period"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faHome} />
                  </ListItemIcon>
                  <ListItemText
                    primary={data.subject && (
                      <a href={`/dashboard/${data.audit.type === 'PERMIT' ? 'permits' : 'rental-units'}/${data.audit.foreign_id}`}>{data.subject.situs_address.concatenated}</a>
                    )}
                    secondary={data.audit && data.audit.type === 'PERMIT' ? 'Permit' : 'Rental Unit'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faUser} />
                  </ListItemIcon>
                  <ListItemText
                    primary={data.audit && data.audit.auditee.name}
                    secondary="Auditee Name"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </ListItemIcon>
                  <ListItemText
                    primary={data.audit && (
                      <a href={`mailto:${data.audit.auditee.email}`} aria-label="E-mail">{data.audit.auditee.email}</a>
                    )}
                    secondary="Auditee E-mail"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faShareSquare} />
                  </ListItemIcon>
                  <ListItemText
                    primary={data.audit && data.audit.listings.length}
                    secondary="Listings"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faUpload} />
                  </ListItemIcon>
                  <ListItemText
                    primary={data.auditListingUploads && data.auditUploads && `${data.auditListingUploads.hits.length} for Listings • ${data.auditUploads.hits.length} for Audit`}
                    secondary="Uploads"
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    {data.audit && (
                      <FontAwesomeIcon icon={statusMap[data.audit.status]} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={data.audit && (
                      <Chip size="small" label={data.audit.status} color={statusColorMap[data.audit.status]} />
                    )}
                    secondary="Status"
                  />
                </ListItem>

                <ListSubheader>Audit Timeline</ListSubheader>

                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faEnvelopeOpenText} />
                  </ListItemIcon>
                  <ListItemText
                    primary={data.audit && dayjs(data.audit.created_at).format('MMMM D, YYYY')}
                    secondary="Audit Initiated"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faFileUpload} />
                  </ListItemIcon>
                  <ListItemText
                    primary={data.letter && dayjs(data.audit.status_at).format('MMMM D, YYYY') || notNull()}
                    secondary="Audit Last Updated"
                  />
                </ListItem>
              </List>
            )}
          </CardContent>
        </Card>
      </Grid>

    </Grid>
  );
}

export default View;
