
const dot = require('dot-object');
import React, { useState } from 'react';
import { FormControl, FormHelperText, TextField, Input, InputLabel } from '@mui/material';
import TextMaskCustom from './input-text-mask';

function View({ question, context }) {

  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    output: context.pick('value') || ''
  });

  const validate = (formValues) => {
    let tmp = { ...formErrors };

    // browser validation... // TODO
    console.warn(formValues);

    // any additional validation...
    // nada

    setFormErrors(tmp);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // console.log(123, name, value);
    // console.warn(dot, context, setFormValues, validate)

    let values = {...formValues};
    dot.str(name, value, values);
    setFormValues(values);
    validate({ [name]: value });
    context.assign({
      value: value
    });
  };

  return (
    <div>
      {question.input.mask && (
        <FormControl error fullWidth>
          <InputLabel htmlFor="text">{question.properties.locale.name}</InputLabel>
          <Input
            id="text"
            name="output"
            placeholder={question.properties.locale.placeholder}
            variant="filled"
            value={formValues.output}
            inputComponent={TextMaskCustom}
            onChange={handleInputChange}
            required={question.input.required}
            readOnly={question.input.readonly}
            autoFocus
            inputProps={{
              maxLength: question.input.maxlength ? question.input.maxlength : 80,
              inputref: 'ref-text',
              mask: question.input.mask
            }}
            autoComplete="off"
            aria-describedby="text-helper-text"
          />
          <FormHelperText id="text-helper-text" style={{fontSize:'0.9rem'}}>{formErrors.text}</FormHelperText>
        </FormControl>
      )}

      {!question.input.mask && (
        <FormControl error fullWidth>
          <TextField
            name="output"
            label={question.properties.locale.name}
            placeholder={question.properties.locale.placeholder}
            variant="filled"
            value={formValues.output}
            onChange={handleInputChange}
            required={question.input.required}
            readOnly={question.input.readonly}
            autoFocus
            inputProps={{
              maxLength: question.input.maxlength ? question.input.maxlength : 80,
              inputref: 'ref-text'
            }}
            autoComplete="off"
            aria-describedby="text-helper-text"
          />
          <FormHelperText id="text-helper-text" style={{fontSize:'0.9rem'}}>{formErrors.text}</FormHelperText>
        </FormControl>
      )}
    </div>
  );
}

export default View;
