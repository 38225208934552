const dot = require("dot-object");
const LZString = require("lz-string");

class QuestionnairesContext {
  master = {};
  persist = [];
  prefix;

  constructor(prefix, _persist = ["answers", "session"]) {
    this.prefix = prefix || "questionnaire.";
    this.persist = _persist;

    this.persist.forEach((key) => {
      const storedValue = sessionStorage.getItem(`${this.prefix}.${key}`);
      if (storedValue) {
        this.master[key] = JSON.parse(
          LZString.decompressFromUTF16(storedValue)
        );
      }
    });
  }

  assign(obj) {
    Object.keys(obj).forEach((to) => {
      dot.del(to, this.master);
      if (obj[to] && typeof obj[to] === "object") {
        if (Array.isArray(obj[to])) {
          dot.str(to, [...obj[to]], this.master);
        } else {
          dot.str(to, { ...obj[to] }, this.master);
        }
      } else {
        dot.str(to, obj[to], this.master);
      }
    });

    this.persist.forEach((key) => {
      if (this.master[key]) {
        sessionStorage.setItem(
          `${this.prefix}.${key}`,
          LZString.compressToUTF16(JSON.stringify(this.master[key]))
        );
      }
    });

    return this.get();
  }

  pick(dotAddr) {
    return dot.pick(dotAddr, this.master, false);
  }

  del(dotAddr) {
    dot.del(dotAddr, this.master);
    return this.assign({});
  }

  get() {
    // NOTE: copy it to aid debugging
    return this.master;
  }
}

export default QuestionnairesContext;
