//
import React, { useEffect } from 'react';
import { Grid, Card, CardContent, Typography, FormControlLabel, Checkbox, Button } from '@mui/material';

//
function View() {

  useEffect(() => {
    document.title = 'Analysts\' | Host Compliance';
  });

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}>
            <Typography variant="h5">Address ID</Typography>
            <Typography variant="subtitle1">Start a Mechanical Turk Address Identification batch</Typography>
          </Grid>

          <Grid item xs={12} md={10}>
            <FormControlLabel control={<Checkbox />} label="Primary only" />
            <FormControlLabel control={<Checkbox />} label="STR only" />
            <FormControlLabel control={<Checkbox />} label="Buffer" />
            <FormControlLabel control={<Checkbox />} label="Days in System Start" />
            <FormControlLabel control={<Checkbox />} label="Days in System End" />
            <FormControlLabel control={<Checkbox />} label="Limit" />
            <Button variant="contained" color="primary">Start</Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default View;
