import React, { useEffect } from 'react';
import { Grid, Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton, FormControlLabel, Checkbox, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faHourglassHalf, faHourglassEnd } from "@fortawesome/free-solid-svg-icons";

function View() {

  useEffect(() => {
    document.title = 'Crawlers | Host Compliance';
  });

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}>
            <Typography variant="h5">Crawlers</Typography>
            <Typography variant="subtitle1">Starts crawls &amp; counts</Typography>
          </Grid>

          <Grid item xs={12} md={10}>
            <FormControlLabel control={<Checkbox />} label="AirBnB" />
            <FormControlLabel control={<Checkbox />} label="HomeAway" />
            <FormControlLabel control={<Checkbox />} label="FlipKey" />
            <FormControlLabel control={<Checkbox />} label="Booking" />
            <FormControlLabel control={<Checkbox />} label="Tripping" />
            <FormControlLabel control={<Checkbox />} label="Counts" />
            <Button variant="contained" color="primary">Start</Button>

            <List dense={true}>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faHourglassHalf} />
                </ListItemIcon>
                <ListItemText
                  primary="Jan 4, 2019"
                  secondary="{ air: true, home: true, flip: false, book: true, trip: false, count: false }"
                />
                <ListItemSecondaryAction>
                  <IconButton aria-label="Pause" size="small">
                    <FontAwesomeIcon icon={faPause} className="warn" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faHourglassEnd} />
                </ListItemIcon>
                <ListItemText
                  primary="Dec 4, 2018"
                  secondary="{ air: true, home: true, flip: false, book: true, trip: false, count: false }"
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default View;
