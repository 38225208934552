//
import React, { useEffect } from 'react';
import { Grid, Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faShare, faVolumeUp, faCar } from "@fortawesome/free-solid-svg-icons";

//
function View() {

  useEffect(() => {
    document.title = 'Complaints | Host Compliance';
  });

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}>
            <Typography variant="h5">Complaints</Typography>
            <Typography variant="subtitle1">Assign undelivered</Typography>
          </Grid>

          <Grid item xs={12} md={10}>
            <List dense={true}>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faVolumeUp} />
                </ListItemIcon>
                <ListItemText
                  primary="Loud Party at 400 Camino De La Placita, Taos, New Mexico"
                  secondary={
                    <div style={{'marginRight': '50px'}}>
                      <div>April 4, 2018 11:22 &middot; Stacy Cooper (+1 603-781-4988) <span className="warn">Emergency Contact Requested</span></div>
                      <div>Nuisance at a Short-term Rental: loud party: TEST</div>
                    </div>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton aria-label="Play" size="small">
                    <FontAwesomeIcon icon={faPlay} />
                  </IconButton>
                  <IconButton aria-label="Share" size="small">
                    <FontAwesomeIcon icon={faShare} className="warn" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faCar} />
                </ListItemIcon>
                <ListItemText
                  primary="Parking Violation at 400 Camino De La Placita, Taos, New Mexico"
                  secondary={
                    <div style={{'marginRight': '50px'}}>
                      <div>April 4, 2018 11:22 &middot; Stacy Cooper (+1 603-781-4988)</div>
                      <div>8 cars parked in no parking areas</div>
                    </div>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton aria-label="Play" size="small">
                    <FontAwesomeIcon icon={faPlay} />
                  </IconButton>
                  <IconButton aria-label="Share" size="small">
                    <FontAwesomeIcon icon={faShare} className="warn" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default View;
