import dayjs from "dayjs";
const dot = require("dot-object");

import { namedArray, deprecatedLog, verboseLog, indentLog, goTo } from "./util";
import parser from "./parser";

class QuestionnairesHooks {
  async chain(groupName, uncompiledHooks, context) {
    const hooks = namedArray(uncompiledHooks, context);

    if (hooks.length > 0) {
      verboseLog(`Hook Group: '${groupName}'`);
      indentLog(hooks);
    }

    for (const hook of hooks) {
      if (parser.logic(hook.active, context) !== false) {
        await this[hook.type.toLowerCase()](hook, context);
      } else {
        verboseLog("SKIPPED", hook.type);
      }
    }
  }

  action(hook, context) {
    return new Promise((resolve) => {
      goTo(hook.action, context);
      resolve();
    });
  }

  cast(hook, context) {
    return new Promise((resolve) => {
      hook.context.forEach((dotPath) => {
        let pre = parser.parse(context.pick(dotPath), context);
        let change = {};

        switch (hook.to.toLowerCase()) {
          case "date":
            change[dotPath] = dayjs(pre)[hook.format ? "format" : "toDate"](
              hook.format
            );
            break;
          case "number":
            change[dotPath] = Number(pre);
            break;
          case "object":
            change[dotPath] = {};
            if (pre && typeof pre === "object") {
              Object.keys(pre).forEach((obj) => {
                const key = dot.pick(hook.key, pre[obj], false);
                if (key) change[dotPath][key] = pre[obj];
              });
            }
            break;
          case "array":
            if (!isNaN(pre)) {
              pre = new Array(pre).fill({});
            }

            change[dotPath] = [];
            pre.forEach((o) =>
              change[dotPath].push(hook.key ? o[hook.key] : hook.default)
            );
            break;
          default:
            change[dotPath] = pre;
        }

        context.assign(change);
      });
      resolve();
    });
  }

  delete(hook, context) {
    return new Promise((resolve) => {
      hook.context.forEach((dotPath) => {
        if (typeof dotPath === "object") {
          context.del(parser.logic(dotPath, context));
        } else {
          context.del(dotPath);
        }
      });
      resolve();
    });
  }

  gql(hook, context) {
    const token = context.pick("session.access_token");
    const token_type = context.pick("session.token_type");
    const currentUrl = window.location.href;
    let options = {
      headers: {
        ...(token && { Authorization: `${token_type} ${token}` }),
        ReactDashboard: "true",
      },
      variables: {},
    };
    if (typeof hook.query === "object") {
      hook.query = parser.logic(hook.query, context);
    }

    if (hook.variables) {
      Object.entries(hook.variables).forEach(([key, value]) => {
        const isDashboardRoute = currentUrl.includes("/dashboard/");
        const isIdKey = key === "id";
        const isJurisdictionId =
          typeof value === "string" && value?.includes("params.jurisdictionId");

        if (isDashboardRoute && isIdKey && isJurisdictionId) {
          options.variables[key] = localStorage.getItem("jurisdictionSlug");
        } else {
          options.variables[key] =
            typeof value === "string"
              ? parser.interpolate(value, context)
              : parser.interpolate(JSON.stringify(value), context);
        }
      });
    }
    return fetch(context.get().config.API_URL, {
      method: "POST",
      headers: options.headers,
      body: JSON.stringify({
        query: hook.query,
        variables: JSON.stringify(options.variables),
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        context.assign({
          results: res.data,
          errors: res.errors,
        });

        if (hook.context) {
          Object.keys(hook.context).forEach((dotFrom) => {
            const dotTo = hook.context[dotFrom];
            const change = {};
            change[dotTo] = parser.interpolate(context.pick(dotFrom), context);
            context.assign(change);
          });
        }

        verboseLog(
          "EXECUTE" + (hook.id ? ` '${hook.id}'` : ""),
          "Gql Context:",
          hook.context
        );
        return res;
      });
  }

  map(hook, context) {
    if (hook.data) {
      deprecatedLog(`${hook.type} hook uses 'data', use 'context'`);
      hook.context = hook.data;
    }

    return new Promise((resolve) => {
      Object.keys(hook.context).forEach((dotFrom) => {
        const dotTo = hook.context[dotFrom];
        const change = {};
        change[dotTo] = parser.parse(context.pick(dotFrom), context);
        context.assign(change);
      });

      verboseLog(
        "EXECUTE" + (hook.id ? ` '${hook.id}'` : ""),
        "Map Context:",
        context.get()
      );
      resolve();
    });
  }

  set(hook, context) {
    if (hook.data) {
      deprecatedLog(`${hook.type} hook uses 'data', use 'context'`);
      hook.context = hook.data;
    }

    return new Promise((resolve) => {
      Object.keys(hook.context).forEach((dotPath) => {
        const change = {};
        change[dotPath] = parser.parse(hook.context[dotPath], context);
        context.assign(change);
      });

      verboseLog(
        "EXECUTE" + (hook.id ? ` '${hook.id}'` : ""),
        "Set Context:",
        hook.context
      );
      resolve();
    });
  }
}

const hooks = new QuestionnairesHooks();
Object.freeze(hooks);
export default hooks;
