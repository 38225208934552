import React, {} from 'react';
import { NavLink } from 'react-router-dom';
import { Stack, Grid, Typography, Card, CardContent, List, ListItem, ListItemText, ListItemIcon, IconButton, Tooltip, Chip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPaperclip, faEnvelope, faPhone, faClipboardCheck, faFilePdf, faComments } from "@fortawesome/free-solid-svg-icons";
import dayjs from 'dayjs';
import { notNull } from '../../common/not-null';
import { statusColorMap } from '../../common/color-map';

const AuditCard = ({ item }) => {
  return (
    <Card className="dashboardCard dashboardCardAudits">
      <CardContent>

        <Stack direction={{ xs: 'column', sm: 'row' }}>
          <Grid item xs="auto">
            <Typography variant="h5">{item.auditee.name}</Typography>
            <Typography variant="subtitle1">Created {dayjs(item.created_at).format('MMMM D, YYYY')}</Typography>
            <Chip size="small" label={item.status} color={statusColorMap[item.status]} />
          </Grid>

          <Grid item xs={12} md={5}>
            <List dense={true}>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faHome} />
                </ListItemIcon>
                <ListItemText
                  primary={item.foreign_id && (
                    <a href={`/dashboard/${item.type === 'PERMIT' ? 'permits' : 'rental-units'}/${item.foreign_id}`}>{item.type === 'PERMIT' ? 'Permit' : 'Rental Unit'}</a>
                  )}
                  secondary="Subject"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faPaperclip} />
                </ListItemIcon>
                <ListItemText
                  primary={`${item.listings.length} listing(s), ${item.uploads.length} upload(s)`}
                  secondary="Attachments"
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} md={5}>
            <List dense={true}>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faEnvelope} />
                </ListItemIcon>
                <ListItemText
                  primary={item.auditee.email || notNull()}
                  secondary="E-mail"
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faPhone} />
                </ListItemIcon>
                <ListItemText
                  primary={item.auditee.telephone || notNull()}
                  secondary="Telephone"
                />
              </ListItem>
            </List>
          </Grid>

          <Stack direction="column" className="actions">
            <Tooltip title="Review" placement="left">
              <IconButton
                aria-label="Review"
                size="small"
                disabled={['REQUESTED', 'CANCELLED', 'ACCEPTED', 'INCOMPLETE', 'ESCALATED', 'NOTICE_SENT', 'OVERDUE'].indexOf(item.status) > -1}
                component={NavLink}
                to={`/dashboard/questionnaires/audits-admin-review?audit_id=${item.id}`}
              >
                <FontAwesomeIcon icon={faClipboardCheck} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Download" placement="left">
              <IconButton aria-label="Download" size="small">
                <FontAwesomeIcon icon={faFilePdf} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Comments" placement="left">
              <IconButton aria-label="Comments" size="small">
                <FontAwesomeIcon icon={faComments} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>

      </CardContent>
    </Card>
  );
}

export default AuditCard;
