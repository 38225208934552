import React from "react";

import SearchView from "../../../components/SearchView/SearchView";
import { loader } from "graphql.macro";

const ManagerQuery = loader("./Manager.graphql");

import ManagerCard from "./ManagerCard";
import ManagerCols from "./ManagerCols.json";

const ManagersList = () => {
  return (
    <SearchView
      Item={ManagerCard}
      searchQuery={ManagerQuery}
      columns={ManagerCols}
      filters={[]}
      title="Managers"
    />
  );
};

export default ManagersList;
