
const dot = require('dot-object');
import React, { useState, createRef } from 'react';
import { Button, TextField, FormControl, FormHelperText } from '@mui/material';
import SignaturePad from 'react-signature-pad-wrapper';

function View({ question, context }) {

  const signaturePadRef = createRef();

  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    output: {
      name: '',
      image_url: ''
    }
  });

  const validate = (formValues) => {
    let tmp = { ...formErrors };

    console.warn(formValues);
    // TODO // any additional validation...
    // if (!formValues.output && !formValues.output.image_url && formValues.output.image_url === '') {
    //   tmp.image_url = question.properties.locale.validation_required;
    // } else {
    //   delete tmp.image_url;
    // }

    setFormErrors(tmp);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let values = {...formValues};
    dot.str(name, value, values);
    setFormValues(values);
    validate({ [name]: value });
    context.assign({
      value: values.output
    });
  };

  //
  let signaturePad;
  const onSignatureBegin = () => {
    if (!signaturePad) signaturePad = signaturePadRef.current;
  };

  const clearSignature = () => {
    if (!signaturePad) signaturePad = signaturePadRef.current;
    signaturePad.clear();

    handleInputChange({
      target: {
        name: 'output.image_url',
        value: ''
      }
    });
  };

  const onSignatureEnd = () => {
    if (!signaturePad) signaturePad = signaturePadRef.current;

    handleInputChange({
      target: {
        name: 'output.image_url',
        value: signaturePad.toDataURL()
      }
    });
  };

  return (
    <div className="inputs inputs-signature">
      <FormControl error fullWidth>
        <TextField
          id="name"
          name="output.name"
          label={question.properties.locale.name}
          variant="filled"
          value={formValues.output.name}
          onChange={handleInputChange}
          required
          autoFocus
          inputProps={{
            inputref: 'ref-name'
          }}
          autoComplete="off"
          aria-describedby="name-helper-text"
        />
        <FormHelperText id="name-helper-text" style={{fontSize:'0.9rem'}}>{formErrors.name}</FormHelperText>
      </FormControl>

      <SignaturePad
        ref={signaturePadRef}
        options={{
          onEnd: onSignatureEnd,
          onBegin: onSignatureBegin
        }}
      />
      <input
        id="image_url"
        name="output.image_url"
        defaultValue={formValues.output.image_url}
        type="hidden"
        aria-describedby="image_url-helper-text"
      />
      <FormHelperText id="image_url-helper-text" style={{fontSize:'0.9rem'}}>{formErrors.image_url}</FormHelperText>

      <nav dir={question.properties.locale.dir}>
        <Button variant="outlined" onClick={clearSignature}>
          {question.properties.locale.clear}
        </Button>
      </nav>
    </div>
  );
}

export default View;
