//
import React, { useEffect } from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';

//
function View() {

  useEffect(() => {
    document.title = 'Post Letter(s) | Host Compliance';
  });

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}>
            <Typography variant="h5">Post Letter(s)</Typography>
            <Typography variant="subtitle1">Configure post</Typography>
          </Grid>

          <Grid item xs={12} md={10}>
            TODO3
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default View;
