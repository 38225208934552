import React, {} from 'react';
import { Stack, Grid, Typography, Card, CardContent, List, ListItem, ListItemText, ListItemIcon, IconButton, Tooltip, Chip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPhone, faComments, faVolumeUp, faArchive, faPlay } from "@fortawesome/free-solid-svg-icons";
import dayjs from 'dayjs';
import { notNull } from '../../common/not-null';

const ComplaintCard = ({ item }) => {
  return (
    <Card className="dashboardCard dashboardCardComplaints">
      <CardContent>

        <Stack direction={{ xs: 'column', sm: 'row' }}>
          <Grid item xs="auto">
            <Typography variant="h5">{dayjs(item.created_at).format('MMMM D, YYYY HH:mm')}</Typography>
            {
              item.contact_requested && (
                <Chip size="small" label="Emergency Contact Requested" color="error" />
              )
            }
          </Grid>

          <Grid item xs={12} md={5}>
            <List dense={true}>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faPhone} />
                </ListItemIcon>
                <ListItemText
                  primary={`${item.reporter.name}, ${item.reporter.telephone} `}
                  secondary="Reporter"
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faHome} />
                </ListItemIcon>
                <ListItemText
                  primary={item.address.concatenated || notNull()}
                  secondary="Reported Address"
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} md={5}>
            <List dense={true}>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faVolumeUp} />
                </ListItemIcon>
                <ListItemText
                  primary={item.type || item.extra.type || notNull()}
                  secondary={item.description}
                />
              </ListItem>
            </List>
          </Grid>

          <Stack direction="column" className="actions">
            <Tooltip title="Comments" placement="left">
              <IconButton aria-label="Comments" size="small">
                <FontAwesomeIcon icon={faComments} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Play" placement="left">
              <IconButton aria-label="Play" size="small">
                <FontAwesomeIcon icon={faPlay} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Archive" placement="left">
              <IconButton aria-label="Archive" size="small">
                <FontAwesomeIcon icon={faArchive} />
              </IconButton>
            </Tooltip>

          </Stack>
        </Stack>

      </CardContent>
    </Card>
  );
}

export default ComplaintCard;
