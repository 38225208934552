import React from "react";

import SearchView from "../../../components/SearchView/SearchView";
import { loader } from "graphql.macro";

const ComplaintQuery = loader("./Complaint.graphql");

import ComplaintCard from "./ComplaintCard";
import ComplaintCols from "./ComplaintCols.json";

const ComplaintsPage = () => {
  return (
    <SearchView
      Item={ComplaintCard}
      searchQuery={ComplaintQuery}
      columns={ComplaintCols}
      filters={[]}
      title="Complaints"
    />
  );
};

export default ComplaintsPage;
