//
import React, { useEffect } from 'react';
import { Grid, Card, CardContent, Typography, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, IconButton } from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faTrash, faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import { LoadingList } from '../../../common/loading';

// TODO3: format item.created_at to date format

const GET_INITIAL = gql`
  {
    clients {
      hits {
        id
        secret
        name
        created_at
        scope
      }
    }
  }
`;

//
function View() {

  useEffect(() => {
    document.title = 'API Clients | Host Compliance';
  });

  const { data, loading, error } = useQuery(GET_INITIAL);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}>
            <Typography variant="h5">API Clients</Typography>
            <Typography variant="subtitle1">Manage and refresh</Typography>
          </Grid>

          <Grid item xs={12} md={10}>
            {loading && LoadingList()}

            {error && (
              <h1>Error! {error.message}</h1>
            )}

            {data && data.clients && data.clients.hits && (
              <List dense={true}>
                <ListItem key="new">
                  <ListItemText primary="Add new user" />
                  <ListItemSecondaryAction>
                    <IconButton aria-label="New" size="small">
                      <FontAwesomeIcon icon={faPlus} className="warn" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                {
                  data.clients.hits.map(item=>{
                    return(
                      <ListItem key={item.id}>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faNetworkWired} />
                        </ListItemIcon>
                        <ListItemText
                          primary={item.name}
                          secondary={
                            <div>
                              <div>Created {item.created_at}</div>
                              <div>Scope: {item.scope.join(', ')}</div>
                            </div>
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton aria-label="Edit" size="small">
                            <FontAwesomeIcon icon={faPen} />
                          </IconButton>
                          <IconButton aria-label="Delete" size="small">
                            <FontAwesomeIcon icon={faTrash} className="warn" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )
                  })
                }
              </List>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default View;
