//
import React, { useEffect } from 'react';
import { Grid, Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton, Button, FormControl, InputLabel, Select, MenuItem, Input } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faFileDownload, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

//
function View() {

  useEffect(() => {
    document.title = 'Imports | Host Compliance';
  });

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}>
            <Typography variant="h5">Imports</Typography>
            <Typography variant="subtitle1">Manage data sources</Typography>
          </Grid>

          <Grid item xs={12} md={10}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Type"
                    value="permit"
                  >
                    <MenuItem value="permit">Permit</MenuItem>
                    <MenuItem value="assessor">Parcels</MenuItem>
                    <MenuItem value="payments">Payments</MenuItem>
                    <MenuItem value="revenue">Revenue</MenuItem>
                    <MenuItem value="parcel-level-policy">Parcel Level Policy</MenuItem>
                    <MenuItem value="address-identification">Analysts&apos; Address Identification Results</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid>
                <label htmlFor="contained-button-file">
                  <Input accept="*/*" id="contained-button-file" multiple type="file" />
                  <Button variant="contained" component="span">
                    Upload
                  </Button>
                </label>
              </Grid>
            </Grid>

            <List dense={true}>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileUpload} />
                </ListItemIcon>
                <ListItemText
                  primary="Permits"
                  secondary="Started 13th Nov"
                />
                <ListItemSecondaryAction>
                  <IconButton aria-label="Download">
                    <FontAwesomeIcon icon={faFileDownload} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileUpload} />
                </ListItemIcon>
                <ListItemText
                  primary="Address Identification Results"
                  secondary="Started 10th Nov"
                />
                <ListItemSecondaryAction>
                  <IconButton aria-label="Download">
                    <FontAwesomeIcon icon={faFileDownload} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileUpload} />
                </ListItemIcon>
                <ListItemText
                  primary="Payments"
                  secondary="Error: Column Mismatch Error: CCV does not exist"
                />
                <ListItemSecondaryAction>
                  <IconButton aria-label="Error">
                    <FontAwesomeIcon icon={faExclamationCircle} className="warn" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileUpload} />
                </ListItemIcon>
                <ListItemText
                  primary="Payments"
                  secondary="Error: Column Mismatch Error: Credit Card does not exist"
                />
                <ListItemSecondaryAction>
                  <IconButton aria-label="Error">
                    <FontAwesomeIcon icon={faExclamationCircle} className="warn" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileUpload} />
                </ListItemIcon>
                <ListItemText
                  primary="Revenue"
                  secondary="Started 13th Nov"
                />
                <ListItemSecondaryAction>
                  <IconButton aria-label="Download">
                    <FontAwesomeIcon icon={faFileDownload} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default View;
