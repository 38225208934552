type tstatusColorMap = {
    [key: string]: 'success' | 'warning' | 'error'
}

const statusColorMap: tstatusColorMap = {
  ACTIVE: 'success',
  ACCEPTED: 'success',
  SENT: 'success',
  PENDING: 'warning',
  PENDING_CHANGE: 'warning',
  PAYMENT_REQUIRED: 'warning',
  REQUESTED: 'warning',
  SUBMITTED: 'warning',
  EXPIRED: 'error',
  DELETED: 'error',
  INACTIVE: 'error',
  DENIED: 'error'
};

export { statusColorMap };
