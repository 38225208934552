import parser from "./parser";
const createHistory = require("history").createBrowserHistory;

const namedArray = (subject) => {
  return subject.order
    ? subject.order.map((k) => {
        return Object.assign({ id: k }, subject.options[k]);
      })
    : subject;
};

const deprecatedLog = (...msg) => {
  const consoleRed =
    "background: rgb(255,87,34); color: white; padding: 2px; border-radius:2px";
  console.log("%cQJSON DEPRECATED WARNING", consoleRed, ...msg);
};

const verboseLog = (...msg) => {
  const consoleGreen =
    "background: rgb(76,175,80); color: white; padding: 2px; border-radius:2px";
  console.log("%cQJSON", consoleGreen, ...msg);
};

const indentLog = (...msg) => {
  console.log("     ", ...msg);
};

const goTo = (rawAction, context) => {
  let history = createHistory({ forceRefresh: true }); // TODO: not force
  const action = parser.parse(rawAction, context);

  if (rawAction < 0) {
    history.go(rawAction);
  } else if (/^(http:\/\/|https:\/\/)/.test(String(action).toLowerCase())) {
    // absolute URL
    window.location.href = action;
  } else if (action[0] === "/") {
    // root-relative URL
    history.push(action);
  } else {
    // questionId
    let pathname = window.location.pathname.split("/");
    pathname.pop();
    pathname.push(action);
    history.push(pathname.join("/"));
  }
};

const reload = (context) => {
  let params = context.pick("params");
  let history = createHistory({ forceRefresh: true }); // TODO: not force
  history.replace(history.location.pathname, { ...params });
};

const copy = (subject) => JSON.parse(JSON.stringify(subject));

export { namedArray, deprecatedLog, verboseLog, indentLog, goTo, copy, reload };
