import React from "react";

import SearchView from "../../../components/SearchView/SearchView";
import { loader } from "graphql.macro";

const RevenueEstimateQuery = loader("./RevenueEstimate.graphql");

import RevenueEstimateCard from "./RevenueEstimateCard";
import RevenueEstimateCols from "./RevenueEstimateCols.json";

const RevenueEstimatesPage = () => {
  return (
    <SearchView
      Item={RevenueEstimateCard}
      searchQuery={RevenueEstimateQuery}
      columns={RevenueEstimateCols}
      filters={[]}
      title="Revenue Estimates"
    />
  );
};

export default RevenueEstimatesPage;
