// TODO: input
import React, { useState, useEffect } from "react";
import { Toolbar, Button, Typography, Tooltip, Box } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import dayjs from "dayjs";
import Approvals from "./approvals";
import parser from "./parser";

function View({ question, context }) {
  const [item] = useState(
    context.pick(parser.interpolate(question.input.item, context), context)
  );
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [currentFile, setCurrentFile] = useState();

  useEffect(() => {
    if (item) {
      setCurrentFile(item?.files[currentFileIndex]);
    }
  }, [item, currentFileIndex]);

  const onStatusChange = (statusValue) => {
    let previousData = context.pick("value");
    if (previousData) {
      context.assign({
        value: {
          ...previousData,
          status: statusValue,
        },
      });
    }
  };

  const onExplanationChange = (details) => {
    let previousData = context.pick("value");
    if (previousData) {
      context.assign({
        value: {
          ...previousData,
          explanation: details,
        },
      });
    }
  };

  const selectFile = (currentItem, index) => {
    setCurrentFileIndex(index);
  };

  const mimeName = (mimetype) => {
    return mimetype.split("/")[0];
  };

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      sx={{ paddingTop: 1, paddingRight: 2, marginRight: 2 }}
    >
      {currentFile && (
        <Box flex={{ md: "1 0 70%" }}>
          <Toolbar
            variant="dense"
            sx={{
              backgroundColor: "#303030",
              color: "#ffffffde",
              padding: "10px",
            }}
          >
            <Button
              disabled={currentFileIndex === 0}
              onClick={() => selectFile(currentFileIndex - 1)}
            >
              <ChevronLeft sx={{ color: "#ffffff42" }} />
            </Button>
            <Typography>
              {currentFileIndex + 1}/{item.files.length}, Uploaded{" "}
              {dayjs(currentFile?.uploaded_at).format("MMMM D, YYYY")}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Tooltip title={currentFile.original_filename}>
              <Button
                href={currentFile.url}
                target="_blank"
                download
                className="download-button"
              >
                Download
              </Button>
            </Tooltip>
            <Button
              disabled={currentFileIndex + 1 === item.files.length}
              onClick={() => selectFile(currentFileIndex + 1)}
            >
              <ChevronRight sx={{ color: "#ffffff42" }} />
            </Button>
          </Toolbar>

          <Box sx={{ p: 2 }}>
            {!currentFile.previews.length && (
              <Typography>
                No preview available, please{" "}
                <a
                  href={currentFile?.url}
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  download {currentFile?.original_filename}
                </a>{" "}
                to review.
              </Typography>
            )}
            {currentFile.previews.map((p, index) => (
              <Box key={index}>
                {["application/pdf"].includes(p.mimetype) && (
                  <Typography>
                    PDF preview not available. Please download to review.
                  </Typography>
                )}
                {mimeName(p.mimetype) === "image" && (
                  <img
                    src={p.url}
                    className="preview-image"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                )}
                {mimeName(p.mimetype) === "audio" && (
                  <audio controls src={currentFile?.url}>
                    Your browser does not support audio.
                  </audio>
                )}
                {mimeName(p.mimetype) === "video" && (
                  <video controls>
                    <source
                      src={currentFile?.url}
                      type={currentFile?.mimetype}
                    />
                    Your browser does not support video.
                  </video>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {question.properties.aside && (
        <Box flex={{ md: "1 0 30%" }} sx={{ paddingLeft: 3, marginLeft: 2 }}>
          <Approvals
            question={question}
            context={context}
            onExplanationChange={onExplanationChange}
            onStatusChange={onStatusChange}
          ></Approvals>
        </Box>
      )}
    </Box>
  );
}

export default View;
