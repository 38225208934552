//
import React, { useEffect } from 'react';
import { Grid, Card, CardContent, List, ListItem, ListItemText, ListItemIcon, Typography, Chip, Stack } from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faEnvelope, faPhone, faHome } from "@fortawesome/free-solid-svg-icons";
import { LoadingList } from '../../../common/loading';
import { notNull } from '../../common/not-null';
import { statusColorMap } from '../../common/color-map';

const GET_INITIAL = gql`
  query(
    $id: ID!
    $permitsQuery: KibanaQL=""
  ) {
    manager(id: $id) {
      id
      jurisdiction_id
      status
      created_at
      updated_at
      name
      email
      telephone
      mailing_address {
        concatenated
      }
      extra
      business_name
    }

    permits(search: { query: $permitsQuery }) {
      total
      hits {
        id
        number
        created_at
        status
        situs_address {
          concatenated
        }
        parcel_number
      }
    }
  }
`;

//
function View({ match }) {

  useEffect(() => {
    document.title = 'Manager | Host Compliance';
  });

  const { data, loading, error } = useQuery(GET_INITIAL, { variables: {
    id: match.params.managerId,
    permitsQuery: '' // TODO3: ElasticSearch or off-proxy manager.id=' + id
  }});

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={9}>
        <Stack>
          <Card variant="outlined" xs="auto">
            <CardContent>
              {loading && LoadingList()}

              {error && (
                <h1>Error! {error.message}</h1>
              )}

              {data && data.manager && (
                <div>
                  <Typography variant="h5" component="div">
                    {data.manager.name || notNull('Untitled')}
                    {data.manager.business_name ? `of ${data.manager.business_name}` : null}
                  </Typography>

                  <Chip
                    size="small"
                    label={data.manager.status}
                    color={statusColorMap[data.manager.status]}
                  />

                  Created {data.manager.created_at}

                  <code>{data.manager.extra}</code>
                </div>
              )}
            </CardContent>
          </Card>

          <Card variant="outlined" xs="auto">
            <CardContent>
              <Typography variant="h5" component="div">Permits</Typography>
              {loading && LoadingList()}

              {data && data.permits && data.permits.hits && (
                <List dense={true}>
                  {
                    data.permits.hits.map(item=>{
                      return(
                        <ListItem key={item.id}>
                          <ListItemIcon>
                            <FontAwesomeIcon
                              icon={item.status === 'ACTIVE' ? faCheck : faTimes}
                              color={item.status === 'ACTIVE' ? 'green' : ''}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <div>
                                <Chip
                                  size="small"
                                  label={item.status}
                                  style={{
                                    display: 'inline',
                                    marginRight: '6px'
                                  }}
                                  color={statusColorMap[item.status]}
                                />
                                <span>{item.situs_address.concatenated}</span>
                              </div>
                            }
                            secondary={`Number: ${item.number} · Parcel ${item.parcel_number}`}
                          />
                        </ListItem>
                      )
                    })
                  }
                </List>
              )}
            </CardContent>
          </Card>
        </Stack>
      </Grid>

      <Grid item xs={12} md={3}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h5" component="div">Contact</Typography>

            {loading && LoadingList()}

            {error && (
              <h1>Error! {error.message}</h1>
            )}

            {data && data.permits && data.permits.hits && (
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </ListItemIcon>
                  <ListItemText primary={data.manager.email || notNull()} secondary="E-mail" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faPhone} />
                  </ListItemIcon>
                  <ListItemText primary={data.manager.telephone || notNull()} secondary="Telephone" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faHome} />
                  </ListItemIcon>
                  <ListItemText primary={data.manager.mailing_address ? data.manager.mailing_address.concatenated : notNull()} secondary="Address" />
                </ListItem>
              </List>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default View;
