import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";

import parser from "./parser";
import hooks from "./hooks";
import { goTo, deprecatedLog, verboseLog, reload } from "./util";

import Address from "./question-address";
import CheckList from "./question-check-list";
import Date from "./question-date";
import Email from "./question-email";
import Filings from "./question-filings";
import ID from "./question-id";
import IdentityExtraction from "./question-identity-extraction";
import Invoice from "./question-invoice";
import Listings from "./question-listings";
import Message from "./question-message";
import Number from "./question-number";
import RadioList from "./question-radio-list";
import Radio from "./question-radio";
import Signature from "./question-signature";
import SigninToken from "./question-signin-token";
import Submission from "./question-submission";
import SummaryMedia from "./question-summary-media";
import Summary from "./question-summary";
import Text from "./question-text";
import Textarea from "./question-textarea";
import UploadSpreadsheet from "./question-upload-spreadsheet";
import Uploads from "./question-uploads";
import { useLocation } from "react-router-dom";

function View({ question, context, goBack }) {
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryStringValue = searchParams.get("value");
  const currentUrl = window.location.href;
  const isFromDashboard = currentUrl.includes("/dashboard/");

  // Loop - To verify multiple documents
  if (question.properties && question.properties.loop) {
    let change = {};
    let index = context.pick("params.index") || 0;
    let arr = context.pick(question.properties.loop);
    let loop = {};

    if (arr) {
      loop.index = index;
      loop.length = arr.length;
      loop.item = arr[loop.index];
    }
    change["loop"] = loop;
    change["params.index"] = index;
    context.assign(change);
  }

  useEffect(() => {
    const fetchData = async () => {
      let value;
      if (question.properties.answer_key) {
        question.properties.context = `answers.${parser.interpolate(
          question.properties.answer_key,
          context
        )}`;
        deprecatedLog(
          `question used 'question.properties.answer_key', use 'question.properties.context'`
        );
      }

      if (question.properties.context) {
        value = context.pick(question.properties.context);

        if (!value && question.properties.answer_default) {
          deprecatedLog(
            `question used 'question.properties.answer_default', use hooks`
          );
          value = parser.parse(question.properties.answer_default, context);
        }

        context.assign({
          value: value ? value : queryStringValue,
        });
      }

      const before =
        question.hooks && question.hooks.before ? question.hooks.before : [];
      await hooks.chain("question.before", before, context);

      const afterLoad =
        question.hooks && question.hooks.after_load
          ? question.hooks.after_load
          : [];
      await hooks.chain("question.after_load", afterLoad, context);
      setLoaded(true);
    };
    fetchData();
  }, []);

  ["title", "prepend", "append"].forEach(
    (key) =>
      (question.properties.locale[key] = parser.parse(
        question.properties.locale[key],
        context
      ))
  );

  context.assign({
    locale: question.properties.locale,
  });

  let submitCount = 0;

  const handleSubmit = async (e) => {
    if (submitCount > 0) return;
    submitCount++;

    if (e && e.preventDefault) e.preventDefault();

    const beforeSave =
      question.hooks && question.hooks.before_save
        ? question.hooks.before_save
        : [];
    const afterSave =
      question.hooks && question.hooks.after_save
        ? question.hooks.after_save
        : [];
    const after =
      question.hooks && question.hooks.after ? question.hooks.after : [];

    if (
      context.pick("params.questionId") === "invoice" &&
      question.input.mutable
    ) {
      var lineItems = context
        .pick("value.lineItems") // eslint-disable-line no-use-before-define
        .filter(function (o) {
          return (o.mutable && o.mutable.active && o.active) || o.active;
        })
        .map(function (o) {
          return {
            type: o.type,
            amount: parseInt(o.amount),
            description: o.description,
          };
        });

      const change = {};
      context.del("answers.extra.lineItems");
      change["answers.extra.lineItems"] = lineItems;
      change["value.lineItems"] = lineItems;
      context.assign(change);

      if (context.pick("value.id")) {
        const queryDetails = {
          query:
            "mutation($id:ID! $lineItems:[EditInvoiceLineItem]!) { updateInvoice(id:$id lineItems:$lineItems) { subtotal } }",
          variables: {
            id: "{{ value.id }}",
            lineItems: "{{ value.lineItems }}",
          },
        };
        let result = await hooks.gql(queryDetails, context);
        if (result.errors) {
          return;
        }
      }
      // end TECHDEBT
    }

    hooks
      .chain("question.before_save", beforeSave, context)
      .then(async () => {
        let change = {};
        if (question.properties.context) {
          change[question.properties.context] = context.pick("value");
        }
        context.assign(change);
        verboseLog("Set Context", change);
        if (question.input.type === "submission") {
          await hooks.gql(question.input.save, context);
        }
      })
      .then(() => hooks.chain("question.after_save", afterSave, context))
      .then(() => hooks.chain("question.after", after, context))
      .then(() => {
        if (
          question.properties.loop &&
          context.pick("loop.index") + 1 !== context.pick("loop.length")
        ) {
          let params = context.pick("params");
          let loop = context.pick("loop");
          context.assign({
            params: {
              ...params,
              index: params.index + 1,
            },
            loop: {
              ...loop,
              index: params.index + 1,
            },
          });
          reload(context);
        } else {
          submitCount--;
          goTo(question.properties.action, context);
        }
      });
  };

  const onGoBack = () => {
    goBack();
  };

  document.onkeydown = (e) => {
    if (
      e &&
      e.key === "Enter" &&
      e.target.name !== "back" &&
      e.target.name !== "submit"
    ) {
      e.preventDefault();
    }
  };

  return !loaded ? (
    <div />
  ) : (
    <form
      onSubmit={handleSubmit}
      className={isFromDashboard ? " qjson dashboardQjson" : "qjson"}
    >
      <h2 dir={question.properties.locale.dir}>
        {question.input.required !== true &&
          [
            "address",
            "date",
            "email",
            "id",
            "number",
            "radio-list",
            "text",
            "textarea",
          ].indexOf(question.input.type) > -1 && (
            <span>{question.properties.locale.validation_optional}: </span>
          )}
        {question.properties.locale.title}
      </h2>

      {question.properties.locale.prepend && (
        <p
          dir={question.properties.locale.dir}
          className="prepend"
          dangerouslySetInnerHTML={{
            __html: parser.interpolate(question.properties.locale.prepend),
          }}
        ></p>
      )}

      {question.input.type === "address" && (
        <Address question={question} context={context}></Address>
      )}
      {question.input.type === "check-list" && (
        <CheckList question={question} context={context}></CheckList>
      )}
      {question.input.type === "date" && (
        <Date question={question} context={context}></Date>
      )}
      {question.input.type === "email" && (
        <Email question={question} context={context}></Email>
      )}
      {question.input.type === "filings" && (
        <Filings question={question} context={context}></Filings>
      )}
      {question.input.type === "id" && (
        <ID question={question} context={context}></ID>
      )}
      {question.input.type === "identity-extraction" && (
        <IdentityExtraction
          question={question}
          context={context}
        ></IdentityExtraction>
      )}
      {question.input.type === "invoice" && (
        <Invoice question={question} context={context}></Invoice>
      )}
      {question.input.type === "listings" && (
        <Listings question={question} context={context}></Listings>
      )}
      {question.input.type === "message" && (
        <Message question={question} context={context}></Message>
      )}
      {question.input.type === "number" && (
        <Number question={question} context={context}></Number>
      )}
      {question.input.type === "radio-list" && (
        <RadioList question={question} context={context}></RadioList>
      )}
      {question.input.type === "radio" && (
        <Radio question={question} context={context}></Radio>
      )}
      {question.input.type === "signature" && (
        <Signature question={question} context={context}></Signature>
      )}
      {question.input.type === "signin-token" && (
        <SigninToken question={question} context={context}></SigninToken>
      )}
      {question.input.type === "submission" && (
        <Submission question={question} context={context}></Submission>
      )}
      {question.input.type === "summary-media" && (
        <SummaryMedia question={question} context={context}></SummaryMedia>
      )}
      {question.input.type === "summary" && (
        <Summary question={question} context={context}></Summary>
      )}
      {question.input.type === "text" && (
        <Text question={question} context={context}></Text>
      )}
      {question.input.type === "textarea" && (
        <Textarea question={question} context={context}></Textarea>
      )}
      {question.input.type === "upload-spreadsheet" && (
        <UploadSpreadsheet
          question={question}
          context={context}
        ></UploadSpreadsheet>
      )}
      {question.input.type === "uploads" && (
        <Uploads question={question} context={context}></Uploads>
      )}

      {question.properties.locale.append && (
        <p
          dir={question.properties.locale.dir}
          className="append"
          dangerouslySetInnerHTML={{
            __html: question.properties.locale.append,
          }}
        ></p>
      )}

      <div>
        {question.properties.action_button &&
          question.properties.action_button.active !== false && (
            <nav
              dir={question.properties.locale.dir}
              className="action-button"
              onClick={() => {
                goTo(question.properties.action_button.action, context);
              }}
            >
              <Button variant="outlined">
                {
                  question.properties.locale[
                    question.properties.action_button.locale_key
                  ]
                }
              </Button>
            </nav>
          )}

        <nav
          dir={question.properties.locale.dir}
          className="back-forward"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {question.properties.back !== false && (
            <Button
              variant="outlined"
              className="back"
              onClick={onGoBack}
              name="back"
              style={{ marginRight: "auto" }}
            >
              Back
            </Button>
          )}

          {question.properties.action && (
            <Button
              variant="outlined"
              className="forward"
              type="submit"
              name="submit"
            >
              <span className="button">
                {question.properties.locale.nav_forward || "Next"}
              </span>
              <span className="enter">
                press <strong>Enter</strong>
              </span>
            </Button>
          )}
        </nav>
      </div>
    </form>
  );
}

export default View;
